<template>
  <div>
    <div
      v-if="!loading"
      style="
        height: 100vh;
        contain: content;
        background: var(--v-greyRaised-base) !important;
      "
    >
      <div
        style="
          height: 36px;
          width: 100%;
          display: flex;
          align-items: center;
          background-color: var(--v-secondary-base) !important;
          background: var(--v-secondary-base) !important;
        "
      >
        <v-btn plain icon class="mx-2" @click="$router.go(-1)"
          ><v-icon>arrow_back</v-icon></v-btn
        >
        <span style="font-size: 14px" class="ml-2"
          ><b>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mr-1"
                  v-on="on"
                  small
                  :color="getMainStatusColor(shipment.documentationStatus)"
                  >fiber_manual_record</v-icon
                >
              </template>
              <span>File Status: {{ shipment.documentationStatus }}</span>
            </v-tooltip>
            {{ shipment.shipmentFile.fileNumber }}</b
          ></span
        >
        <!-- <v-spacer></v-spacer> -->
        <v-tooltip
          bottom
          v-for="relationship in relationships"
          :key="relationship.id"
        >
          <template v-slot:activator="{ on }">
            <v-chip
              small
              class="primaryText--text ml-1"
              v-on="on"
              outlined
              style="border: none"
            >
              <v-icon
                class="mr-2"
                small
                left
                v-if="relationship.organisationRelationship.clientStatus"
                :color="
                  getClientStatusColor(
                    relationship.organisationRelationship.clientStatus
                  )
                "
                >fiber_manual_record</v-icon
              >
              <v-avatar
                size="20"
                :color="
                  relationship.customer && relationship.customer.logo
                    ? 'white'
                    : 'grey'
                "
                left
              >
                <v-img
                  v-if="relationship.customer.logo"
                  :src="relationship.customer.logo"
                  contain
                ></v-img>
                <h3 v-else style="color: white">
                  {{ relationship.customer.name.charAt(0) }}
                </h3>
              </v-avatar>
              <span v-if="relationship.customer.alias">{{
                relationship.customer.alias
              }}</span>
              <span v-else>{{ relationship.customer.name }}</span>
            </v-chip>
          </template>
          <span
            >Customer
            <span v-if="relationship.organisationRelationship.clientStatus">
              - Status:
              {{ relationship.organisationRelationship.clientStatus }}</span
            ></span
          >
        </v-tooltip>
        <v-chip
          v-if="shipment && shipment.breakBulkShipment"
          color="blue"
          outlined
          class="mx-1"
          >Breakbulk</v-chip
        >
        <v-chip outlined style="border: none" class="ml-2" :key="routeKey">
          <v-tooltip bottom v-for="(location, index) in fullRoute" :key="index">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-chip pill small outlined class="primaryText--text">
                  <v-avatar size="24" left>
                    <v-img
                      contain
                      v-if="location.locode"
                      :src="`https://cdn.loglive.io/flags/4x3/${location.locode
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img> </v-avatar
                  >{{ location.locode }}
                </v-chip>

                <v-icon class="mx-3" small v-if="index < fullRoute.length - 1"
                  >east</v-icon
                >
              </div>
            </template>
            <span style="font-size: 12px"
              >{{ location.type }}: {{ location.locode }}</span
            >
          </v-tooltip>
        </v-chip>
      </div>
      <div class="contents">
        <div class="mainContentWrap">
          <v-tabs height="30px" v-model="currentTab" class="tabby" style="margin-right: 55px">
            <v-tab
              v-for="(tab, index) in tabs"
              :key="index"
              :href="`#${tab.value}`"
            >
              {{ tab.name }}
            </v-tab>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" outlined style="border: none">
                  <v-icon left color="grey">outbound</v-icon>
                  <span v-if="shipment.shipper"
                    ><span v-if="shipment.shipper.alias">{{
                      shipment.shipper.alias
                    }}</span>
                    <span v-else>{{ shipment.shipper.name }}</span></span
                  >
                  <span v-else>No Shipper</span>
                </v-chip>
              </template>
              <span>Shipper</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="shipment.shipper && shipment.onBehalfShipper"
            >
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" outlined style="border: none" class="mx-1">
                  <v-icon left color="grey">outbound</v-icon>
                  o.b.o
                  <span v-if="shipment.onBehalfShipper" class="ml-1"
                    ><span v-if="shipment.onBehalfShipper.alias">{{
                      shipment.onBehalfShipper.alias
                    }}</span>
                    <span v-else class="ml-1">{{
                      shipment.onBehalfShipper.name
                    }}</span></span
                  >
                </v-chip>
              </template>
              <span>On behalf of Shipper</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" outlined style="border: none" class="mx-1">
                  <v-icon left color="grey">call_received</v-icon>
                  <span v-if="shipment.consignee">
                    <span v-if="shipment.consignee.alias">{{
                      shipment.consignee.alias
                    }}</span>
                    <span v-else>{{ shipment.consignee.name }}</span>
                  </span>
                  <span v-else>No Consignee</span>
                </v-chip>
              </template>
              <span>Consignee</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" outlined style="border: none" class="mx-1">
                  <v-icon left color="grey">swap_horiz</v-icon>
                  <span v-if="shipment.incoTerm">{{
                    shipment.incoTerm.incoTerm
                  }}</span>
                  <span v-else>No Inco Term</span>
                </v-chip>
              </template>
              <span>Inco Term</span>
            </v-tooltip>

            <v-tooltip bottom v-if="shipment.shippingLine">
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  outlined
                  style="border: none"
                  class="mx-1"
                  @click="editVoyage()"
                >
                  <v-avatar
                    left
                    color="white"
                    size="48"
                    v-if="shipment.shippingLine"
                  >
                    <v-img
                      v-if="shipment.shippingLine && shipment.shippingLine.logo"
                      :src="shipment.shippingLine.logo"
                      contain
                    ></v-img>
                  </v-avatar>
                  <v-icon left v-else :color="'grey'">directions_boat</v-icon>
                  <span v-if="shipment.shippingLine"
                    ><span v-if="shipment.shippingLine.friendlyName"
                      >{{ shipment.shippingLine.friendlyName }}:
                    </span>
                    <span v-else>{{ shipment.shippingLine.name }}: </span>
                  </span>
                  <span v-if="shipment.vessel" class="ml-1"
                    >{{ shipment.vessel.name }} {{ shipment.voyage }}</span
                  >
                  <span v-else>No Vessel</span>
                  <!-- <span v-if="shipment.vessel.imoNumber" class="ml-1" style="color: grey">{{ shipment.vessel.imoNumber }}</span> -->
                </v-chip>
              </template>
              <span>Vessel</span>
            </v-tooltip>

            <v-chip outlined  class="ml-1" style="border: none; margin-right: 60px">
              <v-chip
                :color="shipment.isATD ? 'success' : ''"
                outlined
                style="border: none"
              >
                <v-icon small left>schedule</v-icon>
                <span v-if="shipment.atd" style="font-size: 12px"
                  >ATD:
                  {{ $Format.formatDate(shipment.atd ? shipment.atd : '').dayMonthYear }}</span
                >
                <span v-else style="font-size: 12px"
                  >ETD:
                  {{ $Format.formatDate(shipment.etd ? shipment.etd : '').dayMonthYear }}</span
                >
              </v-chip>
              <v-icon small class="mx-1">arrow_forward</v-icon>
              <v-chip
                :color="shipment.isATA ? 'success' : ''"
                outlined
                style="border: none"
              >
                <v-icon small left>schedule</v-icon>
                <span v-if="shipment.ata" style="font-size: 12px"
                  >ATA:
                  {{ $Format.formatDate(shipment.ata ? shipment.ata : '').dayMonthYear }}</span
                >
                <span v-else style="font-size: 12px"
                  >ETA:
                  {{ $Format.formatDate(shipment.eta ? shipment.eta : '').dayMonthYear }}</span
                >
              </v-chip>
            </v-chip>
          </v-tabs>
          <div class="mainContent">
            
            <div>
              <v-tabs-items
              v-model="currentTab"
              style="height: inherit;"
               class="content-container"
            >
              <v-tab-item style="height: inherit; width: 100%" value="overview">
                <Overview
                  :refreshKey="refreshKey"
                  :shipment="shipment"
                  :relationships="relationships"
                  :containers="containers"
                  :performance="performance"
                  @dateChange="dateChange"
                  @refresh="load(shipment.shipmentReference)"
                  @refreshContainers="getContainers"
                  @refreshContainer="getSingleContainer"
                  @refreshContainerContent="refreshContainerContent"
                  @removeSubscriber="removeSubscriber" @setSubscribers="setNewSubscribers"
                />
              </v-tab-item>
              <v-tab-item
                style="height: inherit; width: 100%"
                value="documentation"
              >
                <Documentation
                  :loadedData="{ shipment, containers }"
                  :containers="containers"
                  :relationships="relationships"
                  :sourceChangeKey="refreshKey"
                />
              </v-tab-item>
              <v-tab-item style="height: inherit; width: 100%" value="uploads">
                <Uploads
                  :shipment="shipment"
                  :bookings="containers"
                  :relationships="relationships"
                />
              </v-tab-item>
              <!-- <v-tab-item style="height: inherit; width: 100%" value="preview">
                <Preview
                />
              </v-tab-item>

              Preview -->
            </v-tabs-items>
            <ChatDrawer :shipment="shipment"/>

            </div>
           
          </div>
        </div>
      </div>

      <v-dialog
        v-model="editVoyageNumber"
        width="400px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <v-card>
          <v-toolbar flat color="transparent">
            <v-toolbar-title> Edit Voyage Number </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              @click="saveVoyage()"
              icon
              :loading="savingVoyage"
              ><v-icon>save</v-icon></v-btn
            >
            <v-btn text @click="editVoyageNumber = false">X</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              placeholder="Voyage No."
              v-model="shipment.voyage"
              outlined
              dense
              clearable
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div
      v-else
      style="
        height: 100%;
        padding-top: 0;
        margin-top: 0;
        background: transparent;
      "
      class="text-center"
    >
      <valhalla-loading />
    </div>
  </div>
</template>
<script>
import ChatDrawer from "./ChatDrawer.vue";
import Documentation from "./Documentation.vue";
import Overview from "./Overview.vue";
// import Preview from "../Integrations/FormPreview/Titan.vue";
import Uploads from "./Uploads.vue";
export default {
  name: "Landing",
  components: {
    ChatDrawer,
    Documentation,
    Overview,
    // Preview,
    Uploads,
  },
  data: () => ({
    comments: [],
    containers: [],
    currentTab: "overview",
    documentComments: [],
    editVoyageNumber: false,
    loading: false,
    loadingComments: false,
    miniVariant: true,
    onHoldWarningModal: false,
    performance: {
      risk: 0,
      complete: 0,
    },
    refreshKey: 200,
    relationships: [],
    routeKey: 100,
    savingVoyage: false,
    shipment: null,
    tabs: [
      { name: "Overview", value: "overview" },
      { name: "Documentation", value: "documentation" },
      { name: "Uploads", value: "uploads" },
      // { name: "Preview", value: "preview" },
    ],
    toggleDrawer: false
  }),
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(value) {
        if (value) {
          this.load(value);
        }
      },
    },
  },
  computed: {
    fullRoute() {
      let result = [];
      let setTypes = [
        { type: "Port of Load", locode: this.shipment.portOfLoadValue },
        {
          type: "Port of Discharge",
          locode: this.shipment.portOfDischargeValue,
        },
        {
          type: "Final Destination",
          locode: this.shipment.finalDestinationValue,
        },
      ];
      result.push(setTypes[0]);
      if (
        this.shipment.sailingSchedule &&
        this.shipment.sailingSchedule.sailingScheduleLegs.length > 1
      ) {
        const findLeg = this.shipment.sailingSchedule.sailingScheduleLegs.find(
          (x) => x.leg === 1
        );
        if (findLeg && findLeg.portOfDischargeValue) {
          result.push({
            type: "TS Port 1",
            locode: findLeg.portOfDischargeValue,
          });
        }
      }
      if (
        this.shipment.portOfDischargeValue ===
        this.shipment.finalDestinationValue
      ) {
        result.push({
          type: "POD & Final Destination",
          locode: this.shipment.finalDestinationValue,
        });
      } else {
        result.push(setTypes[1]);
        result.push(setTypes[2]);
      }
      return result;
    },
  },
  methods: {
    async dateChange(data){
      let updateObj = {
        id: this.shipment.id,
        [data.type]: data.date
      }
      this.shipment = { ...this.shipment, ...updateObj }
      await this.$API.updateShipmentDetail(updateObj)
      this.$message.success('Successfully updated ' + data.type.toUpperCase()+'!')
    },
    editVoyage() {
      this.editVoyageNumber = true;
    },
    async getComments(id) {
      this.loadingComments = true;
      this.comments = await this.$API.getShipmentComments(id);
      this.documentComments = await this.$API.getShipmentDocumentComments(id);
      // this.scrollToEnd();
      this.loadingComments = false;
    },
    async getContainers() {
      if(this.shipment.id){
        this.containers = await this.$API.getBasicShipmentContainers(
        this.shipment.id
      );
      let containsReefers = this.containers.some(
        (x) => x.containerTypeCode && x.containerTypeCode.includes("R")
      );
      this.shipment.reefer = containsReefers;
      this.refreshKey++;
      }
      
    },
    getMainStatusColor(status) {
      switch (status) {
        case "OPEN":
          return "info";
        case "CANCELLED":
          return "warning";
        case "CLOSED":
          return "red";
      }
    },
    async getSingleContainer(id) {
      let data = await this.$API.getSingleShipmentContainer(id);
      let index = this.containers.findIndex((x) => x.id === id);
      this.containers[index] = data;
      this.refreshKey++;
      console.log("Refresh Key", this.refreshKey);
      console.log(this.containers.filter((x) => x.sourceChange));
    },
    async refreshContainerContent(id) {
      let data = await this.$API.getSingleShipmentContainer(id);
      let index = this.containers.findIndex((x) => x.id === id);
      this.containers[index] = data;
      this.refreshKey++;
    },
    async getShipmentRelationships(id) {
      this.relationships = await this.$API.getShipmentRelationships(id);
      this.onHoldWarningModal = this.relationships.some(
        (x) => x.organisationRelationship.clientStatus == "ON HOLD"
      );
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    async load(id) {
      this.loading = true;
      this.shipment = await this.$API.getSingleShipment(id);
      this.getShipmentRelationships(this.shipment.id);
      this.getContainers();
      this.performance = await this.$API.getShipmentDocumentationPerformance(
        this.shipment.id
      );
      if(this.performance.risk == null) {
        this.performance.risk = 0;
      }
      this.loading = false;
    },
    async saveVoyage() {
      this.savingVoyage = true;
      await this.$API.updateShipmentDetail({
        id: this.shipment.id,
        voyage: this.shipment.voyage,
      });
      this.editVoyageNumber = false;
      this.savingVoyage = false;
    },
    setNewSubscribers(subscribers){
      let shipmentSubscribers = [...this.shipment.subscribers, ...subscribers];
      shipmentSubscribers.sort((a, b) => a.user.firstname.localeCompare(b.user.firstname));
      this.shipment.subscribers = shipmentSubscribers
    },
    removeSubscriber(id){
      this.shipment.subscribers = this.shipment.subscribers.filter(x => x.id != id)
    },
  },
};
</script>
<style scoped>
* {
  --gutters: 0.1rem;
  --toolBarHeight: 36px;
  --tabHeight: 30px;
  --darkLayer: var(--v-greyDarker-base) !important;
}

.topBar {
  height: var(--toolBarHeight);
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--v-greyDarker-base) !important;
  background: var(--v-greyDarker-base) !important;
}
.contents {
  display: flex;
  width: 100%;
  height: calc(100vh - var(--toolBarHeight));
  background: var(--v-greyRaised-base) !important;
}
.mainContentWrap {
  background: var(--v-greyRaised-base) !important;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  position: relative;
}
.mainContentTabs {
  height: var(--tabHeight) !important;
}
.mainContent {
  max-height: calc(100vh - var(--toolBarHeight) - var(--tabHeight));
  height: 100%;
  width: 100%;
}
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: var(--v-greyDarker-base) !important;
  border-radius: 0px;
}
.content-container {
  width: calc(100% - 52px);
}
</style>